import React, { useState, useMemo } from 'react';
import { oneOf } from 'prop-types';
import {
  Box,
  Text,
  Spinner,
  FormControl,
  FormLabel,
  Select,
  Input,
  Stack,
  Button,
  Tbody,
  Table,
  Th,
  Tr,
  Td,
  Thead,
  useToast,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Icon,
  Divider,
  Center,
} from '@chakra-ui/react';

import { AiOutlineQuestionCircle as QuestionOutlineIcon } from 'react-icons/ai';

import useDebounce from '@/hooks/useDebounce';
import copyTextToClipboard from '@invitato/helpers/dist/copyTextToClipboard';
import getBroadcastText from '../getBroadcastText';
import useGuestList from '@/usecase/use-guest-list';
import { RSVP_STATUS } from '@constants/api';
import useRsvp from '@/usecase/use-rsvp';

import { THE_BRIDE, HOSTNAME, WEDDING_RESEPSI_TIME } from '@/constants';
import {
  ENABLE_SHIFT_TIME_RECEPTION,
  ENABLE_GUEST_PERSONALIZATION,
  ENABLE_MULTI_LANGUAGE,
} from '@/constants/feature-flags';
import {
  INPUT_PROPS,
  SELECT_PROPS,
  FORM_LABEL_OPTIONS,
  LIST_BROADCAST,
  TH_PROPS,
  OPTION_PROPS,
} from '../types';
import ModalBroadcast from './ModalBroadcast';
import { RiFileCopy2Line, RiWhatsappLine } from 'react-icons/ri';

/**
 * Function to render List Guest Screen
 * @param {props} props
 * @returns {JSX.Element}
 */
function ListGuestScreen({ type }) {
  const { onUpdateRsvp } = useRsvp();
  const toast = useToast();
  const { data: dataGuest, isLoading } = useGuestList();

  const [modalState, setModalState] = useState('');

  const [broadcastId, setBroadcastId] = useState('');
  const [lang, setLang] = useState('');
  const [searchText, setSearchText] = useState('');
  const [showList, setShowList] = useState(20);

  const time = ENABLE_SHIFT_TIME_RECEPTION ? 'Ditampilkan dalam undangan' : WEDDING_RESEPSI_TIME;
  const debounceText = useDebounce(searchText, 500);

  const onClose = () => {
    setModalState('');
  };
  /**
   * Function to get list of broadcast text option
   * @returns {Object[]}
   */
  const broadcastOptions = useMemo(() => LIST_BROADCAST.filter((x) => x.type.includes(type)), [
    type,
  ]);

  const finalGuest = useMemo(() => {
    const searchKey = debounceText.toLowerCase();
    const filterLength = searchKey.length > 0 ? dataGuest.length : showList;

    if (searchKey === 'attend') {
      const attendGuest = dataGuest.filter((item, index) => {
        const text = `${item.status.toLowerCase()}`;

        return text === searchKey && index < filterLength;
      });

      return attendGuest;
    }

    const result = dataGuest.filter((item, index) => {
      const text = `${item.name.toLowerCase()} + ${item.desc.toLowerCase()} ${item.shift} ${
        item.status === RSVP_STATUS.notAttend ? 'not attend' : item.status.toLowerCase()
      }`;

      return text.includes(searchKey) && index < filterLength;
    });

    return result;
  }, [dataGuest, debounceText, showList]);

  const handleCopyText = async (customText) => {
    const result = await copyTextToClipboard(customText);
    if (result) {
      toast({
        status: 'success',
        title: 'Berhasil',
        description: 'Teks berhasil disalin di clipboard.',
      });
    } else {
      toast({
        title: 'Oops!',
        description: 'Terjadi kesalahan. Silahkan muat ulang browser Anda.',
      });
    }
  };

  const handleUpdateSentStatus = (guestInfo) => {
    const { code, status } = guestInfo;

    if (
      status === RSVP_STATUS.attend ||
      status === RSVP_STATUS.notAttend ||
      status === RSVP_STATUS.opened
    ) {
      return;
    }

    onUpdateRsvp({
      guestCode: code,
      updateStatus: RSVP_STATUS.sent,
    });
  };

  const handleChangeBroadcastType = (e) => {
    setBroadcastId(e.target.value);
  };

  const generateLink = (data) => {
    let finalURL = `${HOSTNAME}?to=${encodeURIComponent(data.name)}&type=invitation`;

    // if using QR code?
    if (data.code) {
      finalURL = finalURL + `&code=${data.code}`;
    }
    // duallanguage
    if (ENABLE_MULTI_LANGUAGE) {
      finalURL = finalURL + `&lang=${data.language}`;
    }
    return finalURL;
  };

  const handleClickLink = (guestInfo) => {
    const finalURL = generateLink(guestInfo);
    window.open(finalURL + '&ref=coba', '_blank');
  };

  const handleCopyLinkOnly = (guestInfo) => {
    const finalURL = generateLink(guestInfo);
    handleUpdateSentStatus(guestInfo);
    handleCopyText(finalURL);
  };

  const handleCopyTextBroadcast = (guestInfo, isWhatsApp = false) => {
    if (!broadcastId) {
      toast({
        status: 'error',
        title: 'Oops!',
        description: `Anda harus memilih teks pengantar terlebih dahulu sebelum menggunakan fitur ini.`,
      });

      return;
    }

    const finalURL = generateLink(guestInfo);
    const finalText = getBroadcastText({
      id: broadcastId,
      link: finalURL,
      guestName: guestInfo.name,
      time: time,
    });

    handleUpdateSentStatus(guestInfo);

    if (isWhatsApp) {
      if (!guestInfo.phone_number || guestInfo.phone_number === '-') {
        toast({
          status: 'error',
          title: 'Oops!',
          description: `Nomor HP tidak tersedia. Pastikan Anda sudah memasukan nomor whatsapp di daftar tamu`,
        });

        return;
      }

      window.open(
        `https://wa.me/${guestInfo.country_code}${guestInfo.phone_number}?text=${encodeURIComponent(
          finalText,
        )}`,
        '_blank',
      );
      return;
    }

    handleCopyText(finalText);
  };

  return (
    <Box color="black" fontFamily="Newsreader" paddingTop="24px">
      <Text marginTop="16px" textTransform="uppercase" color="#1A202C">
        The Wedding of
      </Text>
      <Text fontFamily="Newsreader" color="#1A202C" fontSize="3xl">
        {THE_BRIDE}
      </Text>
      <Box>
        <FormControl>
          <FormLabel {...FORM_LABEL_OPTIONS}>Cari Tamu:</FormLabel>
          <Input
            {...INPUT_PROPS}
            placeholder="Cari nama/keterangan/sesi tamu/status.."
            type="text"
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
        </FormControl>
        <FormLabel {...FORM_LABEL_OPTIONS}>Kalimat Pengantar:</FormLabel>
        <Button onClick={() => setModalState('kalimatpengantar')} bgColor="#F0EDE6">
          <Text
            marginBottom="8px"
            color="#1A202C"
            marginLeft="-16px"
            fontSize="14px"
            marginTop="-24px"
            fontWeight="normal"
            fontStyle="italic"
            fontFamily="Newsreader"
          >
            <Icon as={QuestionOutlineIcon} /> Apa itu Kalimat Pengantar?
          </Text>
        </Button>
        <Select
          {...SELECT_PROPS}
          value={broadcastId}
          marginTop="-20px"
          onChange={handleChangeBroadcastType}
        >
          <option {...OPTION_PROPS} value="" style={{ color: 'black' }}>
            --- Pilih Kalimat Pengantar ---
          </option>
          {broadcastOptions.map((item) => {
            return (
              <option
                {...OPTION_PROPS}
                key={item.value}
                value={item.value}
                style={{ color: 'black' }}
              >
                {item.name}
              </option>
            );
          })}
        </Select>
      </Box>
      {ENABLE_MULTI_LANGUAGE && (
        <Box>
          <FormLabel {...FORM_LABEL_OPTIONS}>Bahasa Undangan</FormLabel>
          <Select {...SELECT_PROPS} value={lang} onChange={(e) => setLang(e.target.value)}>
            <option style={{ color: 'black' }} value={`&lang=id`}>
              Indonesia
            </option>
            <option style={{ color: 'black' }} value={`&lang=en`}>
              English
            </option>
          </Select>
        </Box>
      )}

      {/* SECTION TABLE LIST */}
      {isLoading ? (
        <Center height="100px">
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
        </Center>
      ) : (
        <Box overflow="auto" marginTop="24px">
          <Text fontSize="sm" marginBottom="8px" color="#a1425c">
            {`Menampilkan ${finalGuest.length} tamu dari total ${dataGuest.length}`}
          </Text>
          <Table width="100%" variant="unstyled" size="sm">
            <Thead borderBottom="2px solid black" borderTop="2px solid black">
              <Tr>
                <Th {...TH_PROPS}>Kode</Th>
                <Th {...TH_PROPS}>Nama</Th>
                <Th {...TH_PROPS}>Keterangan</Th>
                {ENABLE_SHIFT_TIME_RECEPTION && (
                  <Th {...TH_PROPS} textAlign="center">
                    Sesi
                  </Th>
                )}
                {ENABLE_GUEST_PERSONALIZATION && (
                  <Th {...TH_PROPS} textAlign="center">
                    Jumlah Tamu
                  </Th>
                )}
                <Th {...TH_PROPS} textAlign="center">
                  Jumlah Kehadiran
                </Th>
                <Th {...TH_PROPS} textAlign="center">
                  Status
                </Th>
                <Th {...TH_PROPS} textAlign="center">
                  Link
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {finalGuest.map((d, index) => {
                return (
                  <Tr key={`${index}-${d.code}`}>
                    <Td>{d.code}</Td>
                    <Td>{d.name}</Td>
                    <Td>{d.desc}</Td>
                    {ENABLE_SHIFT_TIME_RECEPTION && <Td textAlign="center">{d.shift}</Td>}
                    {ENABLE_GUEST_PERSONALIZATION && <Td textAlign="center">{d.guest_quota}</Td>}
                    <Td textAlign="center">{d.guest_confirm}</Td>
                    <Td textAlign="center">
                      {d.status === RSVP_STATUS.notAttend ? 'NOT ATTEND' : d.status}
                    </Td>
                    <Td>
                      <Center>
                        <Box display="flex" alignItems="center">
                          <Button
                            marginRight="8px"
                            size="xs"
                            colorScheme="#a1425c"
                            bgColor="#a1425c"
                            onClick={() => handleClickLink(d)}
                          >
                            Coba Link
                          </Button>
                          <Menu>
                            <MenuButton
                              colorScheme="#a1425c"
                              bgColor="#a1425c"
                              size="xs"
                              as={Button}
                              //rightIcon={<FaChevronDown />}
                            >
                              Kirim Link?
                            </MenuButton>
                            <MenuList
                              color="black"
                              bgColor="#E6DBD9"
                              border="1px solid"
                              borderColor="blackAlpha.200"
                            >
                              <MenuItem onClick={() => handleCopyLinkOnly(d)}>
                                <RiFileCopy2Line style={{ marginRight: '4px' }} />
                                Salin Link Saja
                              </MenuItem>
                              <Divider
                                width="100%"
                                bg="blackAlpha.400"
                                borderBottom="none"
                                height="1px"
                              />
                              <MenuItem onClick={() => handleCopyTextBroadcast(d)}>
                                <RiFileCopy2Line style={{ marginRight: '4px' }} />
                                Salin Link + Teks Pengantar
                              </MenuItem>
                              <Divider
                                width="100%"
                                bg="blackAlpha.400"
                                borderBottom="none"
                                height="1px"
                              />
                              <MenuItem onClick={() => handleCopyTextBroadcast(d, true)}>
                                <RiWhatsappLine style={{ marginRight: '4px' }} />
                                Kirim Link + Teks ke WA
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Box>
                      </Center>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
          {showList < dataGuest.length && !searchText && (
            <Box margin="16px 0">
              <Stack>
                <Button
                  colorScheme="facebook"
                  type="button"
                  size="sm"
                  onClick={() => setShowList(showList + 20)}
                >
                  Lihat Lebih Banyak
                </Button>
                <Button
                  colorScheme="red"
                  type="button"
                  size="sm"
                  onClick={() => setShowList(dataGuest.length)}
                >
                  Tampilkan Semuanya
                </Button>
              </Stack>
            </Box>
          )}
        </Box>
      )}

      <ModalBroadcast visible={modalState === 'kalimatpengantar'} onClose={onClose} />
    </Box>
  );
}

ListGuestScreen.propTypes = {
  type: oneOf(['invitation', 'announcement']),
};

export default ListGuestScreen;
